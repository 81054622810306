<template>
  <div class="map-box" ref="mapBox" v-loading="loadingMap" element-loading-background="rgba(0,0,0,0.45)">
    <div class="my-chart" ref="myChart"></div>

    <div class="map-type-list">
      <div class="map-type-item" @click="backTop">返回上级</div>
      <div class="map-type-item" @click="fullScreenMap">全屏预览</div>
    </div>

    <div class="total-card flex justify-around" v-if="totals.length > 0">
      <div class="total-item flex flex-column align-center" v-for="item in totals" :key="item.name">
        <div class="total-title">{{item.name}}</div>
        <count-to-card class="pt-11" :value="item.value" />
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import screenfull from 'screenfull'
import { formatter } from './mapName'
import axios from 'axios'
import XEUtils from 'xe-utils'

/**
 * 1. 地图的tab选项卡（区域产量，企业分布...）对应不同的接口，用 activeMapTab 控制
 * 2. 不同的模块，请求接口时需要传 type: 0总览; 1混凝土; 2砂浆; 3水泥; 0车辆
 */
export default {
  props: {
    totals: { type: Array, default: () => [] },
    type: { type: Number },
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      myChart: null,
      activeMapLevels: [], // 激活的地图层级，全国 > 省 > 市 > 区/县
      loadingMap: false,
      isFullscreen: false,
      mapTabs: [],
      activeMapTab: undefined
    }
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.renderMap()
      }
    }
  },
  created () {
    // 初始化地图区域层级
    this.activeMapLevels = this.getMapLevels()

    // 获取区域层级对应的请求参数
    this.$nextTick(() => {
      // 初始化地图
      const myChart = this.myChart = echarts.init(this.$refs.myChart)
      this.chartResize(myChart) // 监听尺寸变化重新渲染地图
      this.renderMap()
      // 监听地图点击事件(地图下钻), 修改
      myChart.on('click', (ev) => {
        console.log(ev, 'ev')
        // 记录当前地图层级
        const activeMapLevels = this.activeMapLevels
        if (ev.data && (activeMapLevels[activeMapLevels.length - 1].code !== ev.data.code)) {
          this.activeMapLevels.push(ev.data)
          this.renderMap()
        }
      })
    })
  },
  methods: {
    // 初始化地图当前地图的区域 为 账号的areaCode对应的区域
    getMapLevels () {
      const {
        areaType,
        regiProvinCode,
        regiProvinId,
        regiCityCode,
        regiCityId,
        regiCountrCode,
        regiCountrId
      } = this.$store.state.account.userInfo
      // 默认就是全国
      const activeMapLevels = [{ name: '全国', code: 100000, id: undefined }]
      if (areaType == 2) { // 省
        activeMapLevels.push({ code: regiProvinCode, id: regiProvinId })
      }
      if (areaType == 3) { // 市
        activeMapLevels.push({ code: regiProvinCode, id: regiProvinId })
        activeMapLevels.push({ code: regiCityCode, id: regiCityId })
      }
      if (areaType == 4) { // 区县
        activeMapLevels.push({ code: regiProvinCode, id: regiProvinId })
        activeMapLevels.push({ code: regiCityCode, id: regiCityId })
        activeMapLevels.push({ code: regiCountrCode, id: regiCountrId })
      }
      return activeMapLevels
    },
    // 渲染地图
    renderMap () {
      const activeMapLevels = this.activeMapLevels
      const areaCode = activeMapLevels[activeMapLevels.length - 1].code

      let geoJSON

      // 获取区域编码对应的geoJSON数据
      axios.get('/map/' + areaCode + '.json').then((res) => {
        geoJSON = res.data
        const mapName = areaCode === 100000 ? 'china' : 'map'
        echarts.registerMap(mapName, geoJSON)
        const option = this.getOption(areaCode, mapName, geoJSON)
        this.myChart.setOption(option, true)
      })
    },
    getOption (cityCode, mapName, geoJSON) {
      const option = {
        geo: {
          // map: 'china' | '四川'
          // 必须要先引入了对应地图的js文件或者json文件，在这一步的时候，echarts会自动将对应的JS文件注入，地图才会显示.
          map: mapName,
          show: true,
          roam: true, // 不开启缩放和平移
          zoom: 1.3, // 视角缩放比例
          label: {
            formatter,
            show: true,
            color: 'rgb(249, 249, 249)', // 省份标签字体颜色
            fontSize: '11px'
          },
          itemStyle: {
            areaColor: '#031525',
            borderWidth: 1, // 设置外层边框
            borderColor: '#01A2FE'
          }
        },
        legend: {
          icon: 'roundRect',
          data: this.value.map(item => item.name),
          bottom: '20',
          left: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 11, // 文字的字体大小。
            lineHeight: 12
          },
          itemWidth: 12, // 图例标记的图形宽度。
          itemHeight: 12 //  图例标记的图形高度。
        },
        series: [
          {
            type: 'map',
            geoIndex: 0,
            shadowColor: '#3B5077',
            data: geoJSON.features.map(({ properties }, index) => {
              const { value } = { value: 0 }
              return {
                value,
                name: properties.name,
                code: properties.adcode,
                center: properties.centroid
              }
            })
          }
        ]
      }
      this.value.forEach(item => {
        option.series.push({
          name: item.name,
          type: 'scatter',
          coordinateSystem: 'geo',
          data: item.value.map((lngLat) => {
            console.log(lngLat, 'lngLat')
            return (lngLat.split(',').map(a => Number(a)))
          }),
          symbolSize: 5,
          large: true, // 开启大量数据优化
          tooltip: { show: false }
        }
        )
      })
      console.log(option)

      // 由于海南地图包括南海及南海诸岛在内的大片区域，所以显示海南地图时，要将地图放大，并设置海南岛居中显示
      if (cityCode == 460000) {
        option.geo.center = [109.844902, 19.0392]// 中心位置
        option.geo.layoutCenter = ['50%', '40%']// 地图相对容器偏移
        option.geo.layoutSize = '380%'// 地图放大比例
        option.geo.zoom = 1
      } else { // 非显示海南时，将设置的参数恢复默认值
        option.geo.center = undefined
        option.geo.layoutCenter = undefined
        option.geo.layoutSize = '90%'// 地图放大比例
        option.geo.zoom = 1.1
      }
      return option
    },

    changeMapType ({ id }) {
      this.activeMapTab = id
      this.renderMap()
    },

    fullScreenMap () {
      const el = this.$refs.mapBox
      if (screenfull.enabled) {
        screenfull.request(el)
      }
    },

    backTop () {
      if (this.activeMapLevels.length > 1) {
        this.activeMapLevels.pop()
        this.renderMap()
      }
    },

    /**
     * echart 注册resize事件
     */
    chartResize (myChart) {
      const debounced = XEUtils.debounce(myChart.resize, 500)
      window.addEventListener('resize', debounced) // 监听浏览器窗口变化重新加载
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', debounced)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.my-chart {
  width: 100%;
  height: 100%;
}
.map-box {
  position: relative;
}
.map-type-list {
  position: absolute;
  right: 0;
  bottom: 20%;
  .map-type-item {
    margin-bottom: 10px;
    width: 74px;
    line-height: 26px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(90deg, #009CAE 0%, rgba(1, 27, 64, 0.6) 100%);

    &.active {
      background: linear-gradient(90deg, #D60E0C 0%, rgba(1, 27, 64, 0.6) 100%);
    }
  }
}
</style>
