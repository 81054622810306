<template>
  <div class="dlv">
    <div class="page-container">
      <!-- 左 -->
      <slot name="left-pane"></slot>
      <!-- 中 -->
      <slot name="middle-pane"></slot>
      <!-- 右 -->
      <slot name="right-pane"></slot>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      num: 8
    }
  },

  created () {
    let timer = setInterval(() => {
      this.currTime = Date.now()
    }, 1000)

    this.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
      timer = null
    })
  },
  methods: {
    selectedTab ({ id }) {
      this.tabActiveId = id
    }
  }

}
</script>

<style lang="scss" scoped>
// 发光文字

.dlv {
  background: #000 url('~@/assets/imgs/common/dlv-bg.png') no-repeat center;
}

.page-container {
  box-sizing: border-box;
  width: 100%;
  height: calc(100%);
  padding: 20px 20px 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

</style>
