<template>
  <div class="total-count flex">
    <div class="count-item flex justify-center align-center" v-for="(item,i) in nums" :key="i">
      <count-to v-if="item !== '.'" :startVal="0" :endVal="item" :duration="5000" />
      <span v-else>.</span>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to'
import { roundNumber } from '@/utils/tools'
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    nums () {
      return String(roundNumber(this.value)).split('').map(item => {
        return item === '.' ? '.' : Number(item)
      })
    }
  },
  components: {
    CountTo
  }
}
</script>

<style lang="scss" scoped>
.total-count {
  .count-item {
    width: 19px;
    height: 25px;
    background: url('~@/assets/imgs/common/number-bg.png') no-repeat center;
    background-size: 19px 25px;
    position: relative;
    font-size: 20px;
    text-align: center;
    line-height: 24px;
    margin: 0 2.5px;
    @include text-light(#00FFFF);
  }
}
</style>
