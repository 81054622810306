export function formatter (p) {
  switch (p.name) {
    case '内蒙古自治区':
      p.name = '内蒙古'
      break
    case '西藏自治区':
      p.name = '西藏'
      break
    case '新疆维吾尔自治区':
      p.name = '新疆'
      break
    case '宁夏回族自治区':
      p.name = '宁夏'
      break
    case '广西壮族自治区':
      p.name = '广西'
      break
    case '香港特别行政区':
      p.name = '香港'
      break
    case '澳门特别行政区':
      p.name = '澳门'
      break
    case '黑龙江省':
      p.name = '黑龙江'
      break
    case '北京市':
      p.name = '北京'
      break
    case '吉林省':
      p.name = '吉林'
      break
    case '辽宁省':
      p.name = '辽宁'
      break
    case '河北省':
      p.name = '河北'
      break
    case '甘肃省':
      p.name = '甘肃'
      break
    case '青海省':
      p.name = '青海'
      break
    case '四川省':
      p.name = '四川'
      break
    case '云南省':
      p.name = '云南'
      break
    case '贵州省':
      p.name = '贵州'
      break
    case '重庆市':
      p.name = '重庆'
      break
    case '陕西省':
      p.name = '陕西'
      break
    case '山西省':
      p.name = '山西'
      break
    case '河南省':
      p.name = '河南'
      break
    case '湖北省':
      p.name = '湖北'
      break
    case '湖南省':
      p.name = '湖南'
      break
    case '广东省':
      p.name = '广东'
      break
    case '山东省':
      p.name = '山东'
      break
    case '安徽省':
      p.name = '安徽'
      break
    case '江西省':
      p.name = '江西'
      break
    case '江苏省':
      p.name = '江苏'
      break
    case '上海市':
      p.name = '上海'
      break
    case '浙江省':
      p.name = '浙江'
      break
    case '福建省':
      p.name = '福建'
      break
    case '台湾省':
      p.name = '台湾'
      break
    case '海南省':
      p.name = '海南'
      break
    case '天津市':
      p.name = '天津'
      break
  }
  return p.name
}
