<template>
  <div class="dlv-card" ref="dlvCard">
    <span class="icon-fullsceen" v-if="fullScreen" @click="openFullScreen" title="全屏预览">
      <i class="el-icon-zoom-in"></i>
    </span>
    <slot></slot>
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  props: {
    fullScreen: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    openFullScreen () {
      const el = this.$refs.dlvCard
      if (screenfull.enabled) {
        screenfull.request(el)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dlv-card {
  box-sizing: border-box;
  background: #021521;
  border: 1px solid #003F46;
  border-radius: 3px;
  position: relative;
  &:hover .icon-fullsceen {
    display: block;
  }
  .icon-fullsceen {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 999;
    color: white;
    cursor: pointer;
  }
}
</style>
