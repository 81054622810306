
<template>
  <data-view-layout>
    <div
      slot="left-pane"
      class="left-pane">

      <div class="flex mb-15">
        <el-cascader
          class="flex-1"
          size="large"
          v-model="selectedOptions"
          clearable
          placeholder="请选择区域"
          :options="regionOptions"
          :props="regionProps"
          filterable
        ></el-cascader>
        <el-input
          class="flex-1"
          size="large"
          placeholder="请输入企业名称"
          v-model="compName"
          clearable></el-input>
        <el-button size="large" type="primary" icon="el-icon-search" class="custom-button" @click="filterComp"></el-button>
      </div>
      <div class="list-box" style="height: calc(100% - 55px)">
        <div class="p-15 flex">
          <span class="fs-14 flex-1 flex align-center">企业列表</span>
          <el-button size="small" class="custom-button" @click="$router.push('/infoManage/compBaseInfo')">切换回列表</el-button>
        </div>
        <div class="list-content" style="height:calc(100% - 60px);">
          <ResizeWrap style="height: 100%">
            <template v-slot="{height}">
              <vxe-list
                ref="myTree"
                class="my-tree"
                :data="usefulComps"
                :height="height"
                :scroll-y="{gt:0,oSize:50}"
                :auto-resize="true">
                <template #default="{items}">
                  <div style="color: #ffffffd9; text-align:center; margin-top:20px;" v-if="items.length == 0">暂无数据</div>
                  <div v-for="item in items" :key="item.compId" class="pointer" @click="setActiveComp(item)" style="height:150px;border-bottom:1px solid #003F46;line-height: 1em;">
                    <div
                      class="text-1line w-100-p fs-16 relative"
                      style="margin-top: 17px;color: rgba(0, 255, 255, .9);">
                      <span
                        style="display:inline-block;border-bottom:1px solid transparent;padding-bottom:3px;"
                        :style="{borderColor: item.compId==activeComp.compId?'rgba(0, 255, 255, .9)':'transparent'}">
                        {{item.compName}}
                      </span>
                    </div>
                    <p class="fs-12" style="margin-top: 21px;color: rgba(255, 255, 255, .85);">企业编号：{{item.compNumber||'无'}}</p>
                    <p class="fs-12" style="margin-top: 19px;color: rgba(255, 255, 255, .85);">类型：{{item.compTypeName}}</p>
                    <p class="fs-12 text-2line w-100-p" style="margin-top: 18px;color: rgba(255, 255, 255, .85);">地址：{{item.address}}</p>
                  </div>
                </template>
              </vxe-list>
            </template>
          </ResizeWrap>
        </div>
      </div>
    </div>

    <div
      slot="middle-pane"
      class="middle-pane flex-1"
    >
      <BaseViewMap style="width: 100%; height: 100%;" :value="positions"></BaseViewMap>
      <div class="total-card flex justify-around" v-if="totalView.length > 0">
        <div class="total-item flex flex-column align-center" v-for="item in totalView" :key="item.name">
          <div class="total-title">{{item.name}}</div>
          <CountToCard class="pt-11" :value="item.value" />
        </div>
      </div>
    </div>
    <div
      slot="right-pane"
      class="right-pane"
    >
      <div class="drawer-content" >
        <p class="mb-15 flex justify-between" style="color: rgba(0, 255, 255, .9);">{{activeComp.compName}}</p>
        <p class="fs-14" style="line-height: 42px;color: rgba(255, 255, 255, .85);">企业编号：{{activeComp.compNumber||'无'}}</p>
        <p class="fs-14" style="line-height: 42px;color: rgba(255, 255, 255, .85);">类型：{{activeComp.compTypeName}}</p>
        <p class="fs-14" style="line-height: 42px;color: rgba(255, 255, 255, .85);">地址：{{activeComp.address}}</p>
        <div style="width:100%;height:150px;overflow: hidden;" class="relative">
          <BaseMap ref="myMap" :showTools="false" style="width: 100%; height: 300px; position: absolute; top: 50%; transform: translateY(-50%)"></BaseMap>
        </div>
      </div>
    </div>

  </data-view-layout>
</template>

<script>
import { gainRegi, getComps } from '@/apis/common'
import { analysisComp } from '@/apis/dataView/companyView'
import { DataViewLayout } from '@/components/DataView'
import BaseMap from '@/components/BaseMap'
import * as maptalks from 'maptalks'
import BaseViewMap from './components/BaseViewMap'
import { formatLngLat } from '@/components/BaseMap/util'
import CountToCard from '@/components/CountToCard'

export default {
  components: {
    DataViewLayout,
    BaseMap,
    BaseViewMap,
    CountToCard
  },
  data () {
    return {
      mapIns: null,
      layerType: '',
      compMarker: null,
      regionProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code]
                }).then(res => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map(val => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    })
                    )
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      },
      regionOptions: [],
      selectedOptions: [],
      compName: '',
      comps: [],
      usefulComps: [],
      positions: [
        { id: 1, name: '混凝土企业', value: [] },
        { id: 2, name: '砂浆企业', value: [] },
        { id: 3, name: '水泥企业', value: [] },
        { id: 4, name: '沥青企业', value: [] },
        { id: 5, name: '砖块企业', value: [] },
        { id: 6, name: '水稳企业', value: [] },
        { id: 7, name: 'PC构件企业', value: [] },
        { id: 8, name: '砂石企业', value: [] },
        { id: 9, name: '骨料企业', value: [] }
      ],
      activeComp: {},
      totalView: [
        { name: '企业总数', value: 0, prop: 'total' },
        { name: '商砼企业总数', value: 0, prop: 'concreteTotal' },
        { name: '本年新增企业数', value: 0, prop: 'yearTotal' },
        { name: '本年新增商砼企业数', value: 0, prop: 'yearConcreteTotal' }
      ]
    }
  },
  mounted () {
    const { mapIns, layerType } = this.$refs.myMap.init()
    this.mapIns = mapIns
    this.layerType = layerType
    this.initComps()
    this.initTotalView()
    // 获取区域数据
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then(res => {
      if (res.code == 200) {
        const regionOptions = res.data.map(val => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map(valCity => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
        this.regionOptions = regionOptions
      }
    }).catch(val => {
      console.log(val)
    })
  },
  methods: {
    async initTotalView () {
      const res = await analysisComp()
      if (res.code == 200) {
        const data = res.data || {}
        this.totalView = this.totalView.map((item) => {
          const value = data[item.prop] || 0
          item.value = value
          return item
        })
      } else {
        this.$message.error(res.msg)
      }
      console.log(res, 'res')
    },
    filterComp () {
      const selectedIds = this.selectedOptions
      this.usefulComps = this.comps.filter(item => {
        const areaIds = [item.regiProvinId, item.regiCityId, item.regiCountrId].filter(areaId => areaId)
        const includeArea = selectedIds.every((areaId) => areaIds.includes(areaId))

        return item.compName.includes(this.compName) && includeArea
      })
    },
    setActiveComp (compObj = {}) {
      if (this.activeComp.compId != compObj.compId) {
        const position = formatLngLat(compObj.lngLat.split(','), 'gps', this.layerType)
        this.mapIns.setCenter(position)
        this.mapIns.setZoom(16)
        this.activeComp = compObj
        var point
        if (this.compMarker) {
          point = this.compMarker
        } else {
          point = this.compMarker = new maptalks.Marker(
            position,
            {
              symbol: {
                markerFile: require('@/assets/imgs/common/marker_icon.png'),
                markerWidth: 20,
                markerHeight: 20,
                markerDy: 0
              }
            }
          )
          new maptalks.VectorLayer('vector', point).addTo(this.mapIns)
          point.animate(
            {
              symbol: { markerDy: 5 }
            },
            {
              duration: 1000,
              easing: 'upAndDown',
              repeat: true
            }
          )
        }

        console.log(position, 'position')
        point.setCoordinates(position)
      }
    },
    async initComps () {
      const compTypes = {
        1: '混凝土企业',
        2: '砂浆企业',
        3: '水泥企业',
        4: '沥青企业',
        5: '砖块企业',
        6: '水稳企业',
        7: 'PC构件企业',
        8: '砂石企业',
        9: '骨料企业'
      }

      const res = await getComps()
      if (res.code == 200) {
        const comps = this.comps = res.data.rows.map((item) => {
          item.compTypeName = compTypes[item.compType] || '未知'
          this.positions.forEach(pos => {
            if (pos.id == item.compType && item.lngLat) {
              pos.value.push(item.lngLat)
            }
          })
          return item
        })
        this.usefulComps = this.comps
        this.setActiveComp(comps[0])
      } else {
        this.$message.error(res.msg)
      }
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-cascader .el-input__inner,
  .el-input .el-input__inner {
    background: #021521;
    border: 1px solid #01808B!important;
    border-radius: 0;
    color: #fff;
    &:focus, &:hover, &:active {
      border: 1px solid #01808B!important;
      outline: none;
    }
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
// 滚动条部分
.my-tree {
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /*滚动条的轨道*/
  ::-webkit-scrollbar-track {
    background-color: #021521;
  }
  /*滚动条里面的小方块，能向上向下移动*/
  ::-webkit-scrollbar-thumb {
    background-color: rgba(144,147,153,.5);
    border-radius: 5px;
    border: 1px solid #01808B;
    // box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }
  ::-webkit-scrollbar-thumb:hover {
    // background-color: #A8A8A8;
    background-color: rgba(144,147,153,.4);
  }
  ::-webkit-scrollbar-thumb:active {
    background-color: rgba(144,147,153,.5);
  }
  /*边角，即两个滚动条的交汇处*/
  ::-webkit-scrollbar-corner {
    background-color: #01808B;
  }
}

.custom-button {
  background: #05A055!important;
  border-radius: 0!important;
  border: 1px solid #05A055!important;
  color: #fff!important;

  &.deep-button {
    background: #134F54!important;
    border: 1px solid #134F54!important;
  }
}
.list-box {
  background: #021521;
  border: 1px solid #003F46;

  .list-content {
    border-top: 1px solid #003F46;
    margin: 0 15px;
  }
}

.drawer-content {
  width: 100%;
  flex-shrink: 0;
  border: 1px solid #003F46;
  background: #021521;
  height: 100%;
  padding: 27px 14px;
}

.total-card {
  width: 100%;
  position: absolute;
  z-index: 9;
  top: 0;
  .total-title {
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
}

.middle-pane {
  flex: 1;
  margin: 0 11px;
  width: 55%;
  height: 100%;
  position: relative;
}

.left-pane,
.right-pane {
  box-sizing: border-box;
  color: #fff;
  width: 29%;
  height: 100%;
}

.title-line {
  position: absolute;
  top: 8%;
  left: 3%;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .prefix-title {
    display: block;
    width: 6px;
    height: 6px;
    background: #00FFFF;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.map-type-list {
  position: absolute;
  right: 0;
  bottom: 0;

  .map-type-item {
    margin-bottom: 10px;
    width: 74px;
    line-height: 26px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    background: linear-gradient(90deg, #009CAE 0%, rgba(1, 27, 64, 0.6) 100%);

    &.active {
      background: linear-gradient(90deg, #D60E0C 0%, rgba(1, 27, 64, 0.6) 100%);
    }
  }
}

.table-cate-bar {
  position: absolute;
  top: -22px;
  display: flex;

  .cate-item {
    box-sizing: border-box;
    min-width: 60px;
    padding: 0 10px;
    line-height: 22px;
    background: #134F54;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 10px;

    &.active {
      background: #059F55;
    }
  }
}
</style>
